body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Montserrat", sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.modal-content {
  background: #fff !important;
  border: 1px solid transparent !important;
  box-shadow: 0 8px 28px rgba(0, 0, 0, 0.28) !important;
  border-radius: 12px !important;
}

@media (min-width: 744px) {
  .modal-content {
    width: 100% !important;
    max-width: 568px !important;
  }
}

.gmnoprint {
  display: none !important;
}

.riley-button {
  border: 1px solid transparent !important;
  display: block !important;
  width: 100% !important;
  height: 100% !important;
  min-width: 200px !important;
  background-size: 200% 200% !important;
  opacity: 1 !important;
  transition: opacity 1.25s ease 0s !important;
  font-weight: 600 !important;
  background-image: radial-gradient(
    circle at center center,
    rgb(56, 255, 132) 0%,
    rgb(30, 230, 163) 27.5%,
    rgb(28, 227, 151) 40%,
    rgb(4, 215, 180) 57.5%,
    rgb(30, 189, 162) 75%,
    rgb(30, 178, 189) 100%
  ) !important;
}

.modal-footer {
  border-top: 1px solid rgb(235, 235, 235) !important;
}

.modal-header {
  border-bottom: 1px solid rgb(235, 235, 235) !important;
}
