.sf-field-input-container {
  transform: translateZ(0px) !important;
}

.sf-field-outer-style {
  position: relative !important;
  cursor: text !important;
  display: flex !important;
  width: 100% !important;
  margin: 0px !important;
  border: none !important;
  color: rgb(34, 34, 34) !important;
  background-color: rgb(255, 255, 255) !important;
  border-radius: 8px !important;
  font-size: 16px !important;
  line-height: 20px !important;
  font-weight: 400 !important;
}

.sf-field-outer-style-active {
  position: relative !important;
  cursor: text !important;
  display: flex !important;
  width: 100% !important;
  margin: 0px !important;
  border: none !important;
  color: rgb(34, 34, 34) !important;
  background-color: rgb(255, 255, 255) !important;
  border-radius: 8px !important;
  box-shadow: rgb(34, 34, 34) 0px 0px 0px 2px inset !important;
  font-size: 16px !important;
  line-height: 20px !important;
  font-weight: 400 !important;
}

.sf-field-outer-style-error {
  position: relative !important;
  cursor: text !important;
  display: flex !important;
  width: 100% !important;
  margin: 0px !important;
  border: none !important;
  color: rgb(34, 34, 34) !important;
  background-color: rgb(255, 248, 246) !important;
  border-radius: 8px !important;
  box-shadow: rgb(207, 43, 55) 0px 0px 0px 2px inset !important;
  font-size: 16px !important;
  line-height: 20px !important;
  font-weight: 400 !important;
}

.sf-field-label {
  position: relative !important;
  flex: 1 1 0% !important;
  padding: 0px !important;
}

.sf-field-label-inner {
  position: absolute !important;
  top: 18px !important;
  left: 12px !important;
  right: 12px !important;
  margin: 0px !important;
  padding: 0px !important;
  color: rgb(113, 113, 113) !important;
  pointer-events: none !important;
  transform-origin: 0% 0% !important;
  font-size: 16px !important;
  line-height: 20px !important;
  font-weight: 400 !important;
  transition: -ms-transform 0.15s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s,
    -webkit-transform 0.15s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s,
    transform 0.15s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s !important;
}

.sf-field-label-inner-active {
  position: absolute !important;
  top: 18px !important;
  left: 12px !important;
  right: 12px !important;
  margin: 0px !important;
  padding: 0px !important;
  pointer-events: none !important;
  transform-origin: 0% 0% !important;
  font-size: 16px !important;
  line-height: 20px !important;
  transition: -ms-transform 0.15s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s,
    -webkit-transform 0.15s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s,
    transform 0.15s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s !important;
  transform: translateY(-8px) scale(0.75) !important;
  color: rgb(113, 113, 113) !important;
  font-weight: 800 !important;
}

.sf-field-label-inner-error {
  position: absolute !important;
  top: 18px !important;
  left: 12px !important;
  right: 12px !important;
  margin: 0px !important;
  padding: 0px !important;
  pointer-events: none !important;
  transform-origin: 0% 0% !important;
  font-size: 16px !important;
  line-height: 20px !important;
  transition: -ms-transform 0.15s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s,
    -webkit-transform 0.15s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s,
    transform 0.15s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s !important;
  transform: translateY(-8px) scale(0.75) !important;
  color: rgb(207, 43, 55) !important;
  font-weight: 800 !important;
}

.sf-field-label-title {
  max-width: 100% !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.sf-field-input {
  width: 100% !important;
  border: none !important;
  outline: none !important;
  padding: 0px !important;
  margin: 26px 12px 10px !important;
  min-height: 1px !important;
  color: inherit !important;
  background-color: transparent !important;
  font-family: inherit !important;
  font-size: inherit !important;
  font-weight: inherit !important;
  line-height: inherit !important;
  appearance: none !important;
}

.sf-field-textarea {
  width: 100% !important;
  border: none !important;
  outline: none !important;
  padding: 0px !important;
  margin: 26px 12px 10px !important;
  min-height: 1px !important;
  color: inherit !important;
  background-color: transparent !important;
  font-family: inherit !important;
  font-size: inherit !important;
  font-weight: inherit !important;
  line-height: inherit !important;
  appearance: none !important;
}

.sf-field-input:focus {
  color: rgb(34, 34, 34) !important;
}

.sf-input-help-text {
  display: flex !important;
  color: rgb(113, 113, 113) !important;
  font-size: 12px !important;
  line-height: 16px !important;
  font-weight: 400 !important;
  margin-left: 1px !important;
  margin-bottom: 8px !important;
}
