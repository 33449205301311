.rm-marker {
  color: inherit;
  border: none;
  margin: 0px;
  padding: 0px;
  background: transparent;
  width: auto;
  overflow: visible;
  font: inherit;

  display: flex !important;
  outline: none !important;
  text-decoration: none !important;

  z-index: 13 !important;
}

.rm-marker-scale {
  cursor: pointer;
  height: 28px;
  position: relative;
  transform-origin: 50% 50%;
  transition: transform 150ms ease 0s;
  transform: scale(1);
}

.rm-marker-scale:hover {
  transform: scale(1.077);
}

.rm-marker-container {
  background-color: rgb(255, 255, 255);
  border-radius: 28px;
  box-shadow: rgb(0 0 0 / 8%) 0px 0px 0px 1px, rgb(0 0 0 / 18%) 0px 1px 2px;
  clip-path: circle(100% at 50% 50%);
  color: rgb(34, 34, 34);
  height: 28px;
  padding: 0px 8px;
  position: relative;
  transition: clip-path 200ms cubic-bezier(0.35, 0, 0.65, 1) 0s;
}

.rm-marker-text-container {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  opacity: 1;
  transition: opacity 250ms cubic-bezier(0.35, 0, 0.65, 1) 0s;
  white-space: nowrap;
}

.rm-marker-text-span {
  font-weight: 800 !important;
  font-size: 14px !important;
  line-height: 18px !important;
}

.rm-hint-image {
  object-fit: cover;
  width: 100% !important;
  height: 100% !important;
  border-radius: 0px !important;
  padding-top: 15px !important;
  cursor: pointer !important;
}
