.sfe-root-container {
  transform: translateZ(0px) !important;
}

.sfe-section {
  display: inline-flex !important;
  contain: layout !important;
  background-color: rgb(255, 255, 255) !important;
  border-radius: 12px !important;
  padding: 16px !important;
  width: 100% !important;
}

.sfe-icon-container {
  margin-right: 12px !important;
}

.sfe-icon-div {
  -webkit-box-pack: center !important;
  -webkit-box-align: center !important;
  width: 44px !important;
  height: 44px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: rgb(255, 255, 255) !important;
  background: linear-gradient(
    to right,
    rgb(207, 43, 55) 0%,
    rgb(215, 61, 72) 50%,
    rgb(207, 43, 55) 100%
  ) !important;
  border-radius: 50% !important;
}

.sfs-icon-div {
  -webkit-box-pack: center !important;
  -webkit-box-align: center !important;
  width: 44px !important;
  height: 44px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: rgb(255, 255, 255) !important;
  background: linear-gradient(
    to right,
    rgb(56, 255, 132) 0%,
    rgb(30, 230, 163) 27.5%,
    rgb(28, 227, 151) 40%,
    rgb(4, 215, 180) 57.5%
  ) !important;
  border-radius: 50% !important;
}

.sfe-text-large {
  color: #343434 !important;
  font-size: 17px !important;
  line-height: inherit !important;
  margin: 0px !important;
  padding: 0px !important;
  font-weight: 600 !important;
}

.sfe-text-small {
  font-size: 14px !important;
  line-height: 20px !important;
  color: rgb(113, 113, 113) !important;
}
